import ApiService from "@/core/services/api.service";

// action types
export const POST = "request-post";
export const GET = "request-get";
export const PATCH = "request-patch";
export const PUT = "request-put";
export const QUERY = "request-query";
export const DELETE = "request-delete";

// mutation types
const state = {};

const getters = {};

const actions = {
	[POST](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.post(url, data)
				.then(({ data, message }) => {
					resolve({ data, message });
				})
				.catch((response) => {
					reject(response);
				});
		});
	},
	[GET](context, { url }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(url)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[PATCH](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[PUT](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.put(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[QUERY](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
	[DELETE](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.delete(url, data)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
				});
		});
	},
};

const mutations = {};

export default {
	state,
	actions,
	mutations,
	getters,
};
