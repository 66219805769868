import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const GET_PROFILE = "getProfile";

// mutation types
export const SET_PROFILE = "setProfile";
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {
	user_personal_info: {},
	user_account_info: {},
	profile: {},
};

const getters = {
	getProfile(state) {
		return state.profile;
	},
	currentUserPersonalInfo(state) {
		return state.user_personal_info;
	},
	currentUserAccountInfo(state) {
		return state.user_account_info;
	},
	currentUserPhoto(state) {
		return state.user_personal_info.photo;
	},
};

const actions = {
	[GET_PROFILE](context) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get("user")
				.then((output) => {
					const user = ObjectPath.get(output, "data.user");
					context.commit(SET_PROFILE, user);
					resolve(user);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[UPDATE_PERSONAL_INFO](context, payload) {
		context.commit(SET_PERSONAL_INFO, payload);
	},
	[UPDATE_ACCOUNT_INFO](context, payload) {
		context.commit(SET_ACCOUNT_INFO, payload);
	},
};

const mutations = {
	[SET_PROFILE](state, profile) {
		state.profile = profile;
	},
	[SET_PERSONAL_INFO](state, user_personal_info) {
		state.user_personal_info = user_personal_info;
	},
	[SET_ACCOUNT_INFO](state, user_account_info) {
		state.user_account_info = user_account_info;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
