import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import listing from "./listing.module";
import contact from "./contact.module";
import common from "./common.module";
import request from "./request.module";
import excel from "./excel.module";
import reminder from "./reminder.module";
import dashboard from "./dashboard.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		listing,
		auth,
		htmlClass,
		config,
		breadcrumbs,
		profile,
		common,
		contact,
		excel,
		request,
		reminder,
		dashboard,
	},
});
