export const getData = (key) => {
	try {
		let userString = window.localStorage.getItem(key);
		let userArr = JSON.parse(userString);
		return userArr;
	} catch (err) {
		return new Object();
	}
};

export const deleteData = (key) => {
	window.localStorage.removeItem(key);
};

export const saveData = (key, data) => {
	try {
		window.localStorage.removeItem(key);
		window.localStorage.setItem(key, JSON.stringify(data));
	} catch (e) {
		if (e.name === "QuotaExceededError") {
			window.localStorage.clear();
			window.location.reload(true);
		}
	}
};

export const save_raw_data = (key, data) => {
	try {
		window.localStorage.removeItem(key);
		window.localStorage.setItem(key, data);
	} catch (e) {
		if (e.name === "QuotaExceededError") {
			window.localStorage.clear();
			window.location.reload(true);
		}
	}
};

export default { saveData, getData, deleteData, save_raw_data };
