import ApiService from "@/core/services/api.service";
import { orderBy, filter, cloneDeep } from "lodash";
import objectPath from "object-path";

// action types
export const GET_LISTING = "queryListing";
export const GET_SINGLE_ROW = "querySingleRow";
export const UPDATE_TABLE = "updateTable";
export const BULK_ACTION = "bulkAction";
export const BEFORE_DESTROY = "beforeDestroy";

// Settings Action types
export const GET_SETTINGS = "getSettings";
export const SET_SETTINGS = "setSettings";

// mutation types
export const SET_STATUS = "setStatus";
export const SET_FILTER = "setFilter";
export const SET_ACTION = "setAction";
export const SET_BULK_ACTION = "setBulkAction";
export const SET_TBODY = "setTbody";
export const SET_THEAD = "setThead";
export const SET_CURRENT_PAGE = "setCurrentPage";
export const SET_TOTAL_PAGE = "setTotalPage";
export const SET_SELECTED_ROWS = "setSelectedRows";
export const SET_SEARCH_MODEL = "setSearchModel";
export const SET_SEARCH_TITLE = "setSearchTitle";
export const SET_SHOWING_STRING = "setShowingString";
export const SET_ERROR = "setError";
export const SET_SEARCH_BAR = "setSearchBar";

export const SORT_TBODY = "sortTbody";

export const SET_TOTAL_ROWS = "setTotalRows";

export const SET_COUNT = "setCount";

export const SET_LOADING = "setLoading";

export const SET_TOTAL = "setTotal";
export const SET_TOTAL_ACTIVE = "total_active";
export const SET_TOTAL_EXPIRE = "total_expire";
export const SET_TOTAL_EXPIRE_IN_MONTH = "total_expire_1_month";
// export const SET

const state = {
	errors: [],
	action: [],
	bulk_action: [],
	status: [],
	filter: [],
	tbody: [],
	thead: [],
	selected: [],
	current_page: 1,
	total_page: 0,
	showing_string: null,
	search_enabled: false,
	search_title: null,
	search_model: null,
	loading: false,

	total: null,
	total_rows: null,
	total_active: null,
	total_expire: null,
	total_expire_1_month: null,
	count: null,

	// settings
	settings: new Object(),
};

const getters = {
	tbody(state) {
		return state.tbody || [];
	},
	_pageLoading(state) {
		return state.loading || false;
	},
	// settings getters start
	moduleSettings(state) {
		return state.settings || new Object();
	},
	// settings getters end
	searchEnabled(state) {
		return state.search_enabled || false;
	},
	searchTitle(state) {
		return state.search_title || null;
	},
	listingSearch(state) {
		return state.search_model || null;
	},
	listingStatus(state) {
		return state.status || [];
	},
	listingFilter(state) {
		return state.filter || [];
	},
	listingAction(state) {
		return state.action || [];
	},
	listingBulkAction(state) {
		return state.bulk_action || [];
	},
	currentPage(state) {
		return state.current_page || 1;
	},
	showingString(state) {
		return state.showing_string || null;
	},
	totalPage(state) {
		return state.total_page || 0;
	},
	sortedThead(state) {
		const result = orderBy(state.thead, ["order"], ["asc"]);
		return result
			? filter(result, {
					visible: true,
			  })
			: [];
	},
	theadShow(state) {
		const result = orderBy(state.thead, ["order"], ["asc"]);
		return result
			? filter(result, {
					visible: true,
			  }).map((col) => col.key)
			: [];
	},
	thead(state) {
		return state.thead || [];
	},
	selected(state) {
		return state.selected || [];
	},
	total_active(state) {
		return state.total_active;
	},
	total_expire(state) {
		return state.total_expire;
	},
	total_expire_1_month(state) {
		return state.total_expire_1_month;
	},
	total_rows(state) {
		return state.total_rows;
	},
	total(state) {
		return state.total;
	},
	count(state) {
		return state.count;
	},
};

const actions = {
	[BEFORE_DESTROY](context) {
		context.commit(SET_ACTION, []);
		context.commit(SET_BULK_ACTION, []);
		context.commit(SET_STATUS, []);
		context.commit(SET_FILTER, []);
		context.commit(SET_TBODY, []);
		context.commit(SET_THEAD, []);
		context.commit(SET_ERROR, []);
		context.commit(SET_CURRENT_PAGE, 1);
		context.commit(SET_TOTAL_PAGE, 0);
		context.commit(SET_SHOWING_STRING, null);
		context.commit(SET_SEARCH_BAR, false);
		context.commit(SET_SEARCH_TITLE, null);
		context.commit(SET_SETTINGS, null);
	},
	[GET_LISTING](context, { url, filter }) {
		context.commit(SET_LOADING, true);
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.query(url, filter)
				.then(({ data }) => {
					context.commit(SET_ACTION, objectPath.get(data, "action") ?? []);
					context.commit(SET_BULK_ACTION, objectPath.get(data, "bulk_action") ?? []);
					context.commit(
						SET_CURRENT_PAGE,
						objectPath.get(data, "current_page") ?? objectPath.get(data, "currentPage")
					);
					context.commit(
						SET_TOTAL_PAGE,
						objectPath.get(data, "total_page") ?? objectPath.get(data, "total_pages")
					);
					context.commit(SET_SHOWING_STRING, data.showing_string);
					context.commit(SET_STATUS, data.status);
					context.commit(SET_FILTER, data.filter);
					context.commit(SET_TBODY, data.tbody || data.renews);
					context.commit(SET_THEAD, data.thead || []);
					context.commit(SET_TOTAL_ACTIVE, data.total_active);
					context.commit(SET_TOTAL_ROWS, data.totalRows);
					context.commit(SET_TOTAL, data.total);
					context.commit(SET_TOTAL_EXPIRE, data.total_expire);
					context.commit(SET_TOTAL_EXPIRE_IN_MONTH, data.total_expire_1_month);
					context.commit(SET_COUNT, data.count);
					resolve(data);
				})
				.catch((response) => {
					console.log({ response });
					context.commit(SET_ERROR, response.data.message);
					reject(response);
				})
				.finally(() => {
					context.commit(SET_LOADING, false);
				});
		});
	},
	[UPDATE_TABLE](context, { type, params }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(`table/${type}`, params)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.message);
					reject(response);
				});
		});
	},
	[BULK_ACTION](context, { type, url, selected }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.patch(url, {
				action: type,
				selected,
			})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.message);
					reject(response);
				});
		});
	},
	[SET_SEARCH_BAR](state, payload) {
		state.commit(SET_SEARCH_BAR, payload);
	},
	[SET_SEARCH_TITLE](state, payload) {
		state.commit(SET_SEARCH_TITLE, payload);
	},
	[SET_SEARCH_MODEL](state, payload) {
		state.commit(SET_SEARCH_MODEL, payload);
	},
	[GET_SETTINGS](context, { url }) {
		return new Promise((resolve, reject) => {
			ApiService.setHeader();
			ApiService.get(url)
				.then(({ data }) => {
					context.commit(SET_SETTINGS, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.message);
					reject(response);
				});
		});
	},
	[GET_SINGLE_ROW](context, id) {
		let tbody = objectPath.get(state, "tbody");
		if (tbody && Array.isArray(tbody)) {
			let row = tbody.find((v) => v.id == id);
			return row;
		} else {
			return "Listing not found";
		}
	},
	[SORT_TBODY](context, sort) {
		const {
			state: {
				thead,
				/* tbody */
			},
			commit,
			dispatch,
		} = context;

		/* const tbodyClone = cloneDeep(tbody); */

		const {
			sortType,
			thead: { key },
			endpoint,
			query,
		} = sort;

		let theadClone = cloneDeep(thead);
		let indexOfTheadWhereKeyFound = theadClone.findIndex((th) => th.key == key);

		if (indexOfTheadWhereKeyFound > -1) {
			theadClone[indexOfTheadWhereKeyFound].sort = sortType;
			theadClone[indexOfTheadWhereKeyFound].sortable = true;
			theadClone = orderBy(theadClone, ["order"], ["asc"]);

			commit(SET_THEAD, theadClone);

			dispatch(UPDATE_TABLE, {
				type: theadClone[indexOfTheadWhereKeyFound].type,
				params: new Object({
					tables: theadClone,
				}),
			})
				.catch((err) => {
					console.log({
						err,
					});
				})
				.finally(() => {
					dispatch(GET_LISTING, {
						url: endpoint,
						filter: {
							...query,
							status: "all",
							reminder_sort: sortType,
						},
					});
				});
		}

		/* const tbodyCloneArrayWithReminder = tbodyClone.filter((row) => {
            if (row.reminder) {
                return row;
            }
        });
        const tbodyCloneArrayWithOutReminder = tbodyClone.filter((row) => {
            if (row.reminder == null) {
                return row;
            }
        }); */

		/* const N = tbodyCloneArrayWithReminder.length;

        if (tbodyCloneArrayWithReminder && tbodyCloneArrayWithReminder.length) {
            quickSort(tbodyCloneArrayWithReminder, 0, N - 1, sortType);
            let FinalResult;
            if (sortType == "ASC") {
                FinalResult = [...tbodyCloneArrayWithReminder, ...tbodyCloneArrayWithOutReminder];
            } else {
                FinalResult = [...tbodyCloneArrayWithOutReminder, ...tbodyCloneArrayWithReminder];
            }
            context.commit(SET_TBODY, FinalResult);
        } */
	},
};

const mutations = {
	[SET_ACTION](state, data) {
		state.action = data;
	},
	[SET_LOADING](state, data) {
		state.loading = data;
	},
	[SET_BULK_ACTION](state, data) {
		state.bulk_action = data;
	},
	[SET_CURRENT_PAGE](state, data) {
		state.current_page = data;
	},
	[SET_TOTAL_PAGE](state, data) {
		state.total_page = data;
	},
	[SET_SHOWING_STRING](state, data) {
		state.showing_string = data;
	},
	[SET_STATUS](state, data) {
		state.status = data;
	},
	[SET_FILTER](state, data) {
		state.filter = data || [];
	},
	[SET_TBODY](state, data) {
		state.tbody = data;
	},
	[SET_SETTINGS](state, data) {
		state.settings = data;
	},
	[SET_THEAD](state, data) {
		state.thead = data;
	},
	[SET_SELECTED_ROWS](state, rows) {
		state.selected = rows;
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_SEARCH_TITLE](state, title) {
		state.search_title = title;
	},
	[SET_SEARCH_BAR](state, status) {
		state.search_enabled = status;
	},
	[SET_SEARCH_MODEL](state, model) {
		state.search_model = model;
	},
	[SET_TOTAL_ACTIVE](state, data) {
		state.total_active = data;
	},
	[SET_TOTAL_EXPIRE](state, data) {
		state.total_expire = data;
	},
	[SET_TOTAL_EXPIRE_IN_MONTH](state, data) {
		state.total_expire_1_month = data;
	},
	[SET_TOTAL_ROWS](state, data) {
		state.total_rows = data;
	},
	[SET_TOTAL](state, data) {
		state.total = data;
	},
	[SET_COUNT](state, data) {
		state.count = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};

/* function getDateTime(date) {
    return new Date(date).getTime();
} */

/* function partition(arr, low, high, sortType = "ASC") {
    let compareWithLast = getDateTime(arr[high].reminder.start_date);
    let i = low - 1;
    for (let j = low; j <= high - 1; j++) {
        let condition =
            sortType == "ASC" ?
            getDateTime(arr[j].reminder.start_date) < compareWithLast :
            getDateTime(arr[j].reminder.start_date) > compareWithLast;

        if (condition) {
            i++;
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
    }
    [arr[i + 1], arr[high]] = [arr[high], arr[i + 1]];
    return i + 1;
} */

/* function quickSort(arr, low, high, sortType) {
    if (low < high) {
        let pi = partition(arr, low, high, sortType);
        quickSort(arr, low, pi - 1, sortType);
        quickSort(arr, pi + 1, high, sortType);
    }
} */
