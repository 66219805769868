import Vue from "vue";

export const InitializeError = (response) => {
	let requestErrors = [];
	let data = response.data;
	if (data && data.message) {
		requestErrors.push(data.message);
	} else if (response && response.status === 422) {
		if (data.errors) {
			for (let error in data.errors) {
				if (data.errors[error]) {
					for (let i = data.errors[error].length - 1; i >= 0; i--) {
						requestErrors.push(data.errors[error][i]);
					}
				}
			}
		} else {
			for (let error in data) {
				if (data[error]) {
					for (let i = data[error].length - 1; i >= 0; i--) {
						requestErrors.push(data[error][i]);
					}
				}
			}
		}
	} else if (response && response.status === 207) {
		requestErrors.push(data.error);
	} else if (response && response.status === 401) {
		requestErrors.push(data.error);
	} else if (response && response.status === 500) {
		requestErrors.push(data.message);
	} else if (response && response.status === 400) {
		requestErrors.push(data.message);
	} else if (response && response.status === 404) {
		requestErrors.push("Sorry ! The page you requested was not found.");
	} else if (response && response.status === 405) {
		requestErrors.push(data.message);
	} else if (typeof response === "string") {
		requestErrors.push(response);
	} else {
		console.log({ error: response });
		requestErrors.push("Something went wrong, Please try again later.");
	}
	return requestErrors;
};

export const ErrorEventBus = new Vue();

export const SuccessEventBus = new Vue();

export const ClearEventBus = new Vue();
